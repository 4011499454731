<template>
  <div class="send-help">
    <div class="help-title">
      {{ $t('views.help.title') }}
    </div>
    <edudash-input
      v-model="school"
      rules="required"
      prepend-icon="home"
      :name="$t('components.labels.school_name')"
      :label="$t('components.labels.school_name')"
    />
    <edudash-input
      v-model="email"
      rules="required|email"
      prepend-icon="email"
      :name="$t('components.labels.email')"
      :label="$t('components.labels.email_secondary')"
    />
    <edudash-input
      v-model="cpf"
      rules="required|cpf"
      prepend-icon="description"
      :name="$t('components.labels.cpf_secondary')"
      :label="$t('components.labels.cpf')"
      :mask="cpfMask"
    />
    <edudash-input
      v-model="birthday"
      :mask="birthdayMask"
      rules="required"
      :name="$t('components.labels.birthday_secondary')"
      :prepend-icon="'date_range'"
      :label="$t('components.labels.birthday')"
    />
    <edudash-button
      class="edudash-button mt-4"
      :full-width="false"
      @click="sendHelp"
    />
  </div>
</template>
<script>
import EdudashButton from '@/components/forms/EdudashButton.vue';
import EdudashInput from '@/components/forms/EdudashInput.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Help',
  components: {
    EdudashButton,
    EdudashInput,
  },
  data() {
    return {
      school: '',
      email: '',
      cpf: '',
      birthday: '',
    };
  },
  computed: {
    ...mapGetters([
      'getHelp',
    ]),
    cpfMask() {
      return '###.###.###-##';
    },
    birthdayMask() {
      return '##/##/####';
    },
  },
  watch: {
    getHelp(value) {
      if (value) {
        this.addSuccessMessage(this.$t('views.help.success'));
        this.$router.push({
          name: 'login',
        });
      }
    },
  },
  mounted() {
    this.changeToolbarText(this.$t('components.labels.register_help'));
  },
  methods: {
    ...mapActions([
      'registerHelp',
      'addSuccessMessage',
      'changeToolbarText',
    ]),
    sendHelp() {
      const userData = {
        cpf: this.unmask(this.cpf),
        birthday: this.birthday,
        school: this.school,
        email: this.email,
      };
      this.registerHelp(userData);
    },
  },
};
</script>
<style lang="scss">
.send-help {
  padding: 16px;

  .help-title {
    @include font-monteserrat-regular;
    font-size: $font-size-extra-small;
    margin-bottom: 16px;
    color: $text-color-secondary;
  }

  .v-icon.v-icon {
    color: $text-color-secondary;
  }

  .v-label {
    color: $text-color-secondary;
  }

  .edudash-button {
    width: 164px;
  }
}
</style>
